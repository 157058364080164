import type VueRouter from 'vue-router'
import type { RouteConfig, RawLocation } from 'vue-router'
import type { ErrorHandler } from 'vue-router/types/router';

export class RouterManager {
  private _registeredRoutes: Array<RouteConfig>;

  private _router: VueRouter;

  private _routeLock: {
    lockPromise: Promise<any>,
    resolver: Function,
  } | null;

  private _routeDispatched: boolean;

  private _callbacks: Function[];

  private static _instance: RouterManager;

  public static getInstance(router?: VueRouter) {
    if (router) {
      RouterManager._instance = new RouterManager(router);
    }

    return RouterManager._instance;
  }

  private constructor(router: VueRouter) {
    this._router = router;

    this._registeredRoutes = [];
    this._routeLock = null;
    this._routeDispatched = false;
    this._callbacks = [];
  }

  addRoutes(routes: RouteConfig[], routerInstance: VueRouter = this._router): void {
    const uniqueRoutes = routes.filter(
      (route) => this._registeredRoutes.findIndex(
        (registeredRoute) => registeredRoute.name === route.name && registeredRoute.path === route.path
      ) === -1
    )
    if (uniqueRoutes.length > 0) {
      this._registeredRoutes.push(...uniqueRoutes)
      routerInstance.addRoutes(uniqueRoutes)
    }
  }

  addDispatchCallback(callback: Function) {
    this._callbacks.push(callback);
  };

  findByName(name: string): RouteConfig {
    return this._registeredRoutes.find(r => r.name === name)
  }

  findByPath(path: string): RouteConfig {
    return this._registeredRoutes.find(r => r.path === path)
  }

  lockRoute() {
    let resolver
    this._routeLock = {
      lockPromise: new Promise(resolve => { resolver = resolve }),
      resolver
    }
  }

  push(location: RawLocation, onComplete?: Function, onAbort?: ErrorHandler) {
    return this._router.push(location, onComplete, onAbort);
  }

  isRouteProcessing() {
    return !!this._routeLock
  }

  isRouteDispatched() {
    return !!this._routeDispatched
  }

  getRouteLockPromise() {
    if (this._routeLock) return this._routeLock.lockPromise
    return Promise.resolve()
  }

  get router() {
    return this._router;
  }

  unlockRoute() {
    if (this._routeLock) {
      this._routeLock.resolver()
      this._routeLock = null
    }

    this._routeDispatched = true
    this._callbacks.forEach(callback => {
      callback()
    });
  }
}
