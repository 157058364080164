import rootStore from '../../store'
import { I18n } from '@vue-storefront/i18n'
import { initializeSyncTaskStorage } from '../sync/task'
import Vue from 'vue'
import queryString from 'query-string'
import { RouterManager } from '@vue-storefront/core/lib/router-manager'
import VueRouter, { RouteConfig, RawLocation } from 'vue-router'
import config from 'config'
import { LocalizedRoute, StoreView } from '../types'
import storeCodeFromRoute from '../storeCodeFromRoute'
import { currentStoreView } from './currentStoreView';

export { currentStoreView } from './currentStoreView';

export async function prepareStoreView (storeCode: string): Promise<StoreView> {
  let storeView = { // current, default store
    tax: Object.assign({}, config.tax),
    i18n: Object.assign({}, config.i18n),
    elasticsearch: Object.assign({}, config.elasticsearch),
    storeCode: '',
    storeId: config.defaultStoreCode && config.defaultStoreCode !== '' ? config.storeViews[config.defaultStoreCode].storeId : 1
  }
  const storeViewHasChanged = !rootStore.state.storeView || rootStore.state.storeView.storeCode !== storeCode
  if (storeCode) { // current store code
    if ((storeView = config.storeViews[storeCode])) {
      storeView.storeCode = storeCode
      rootStore.state.user.current_storecode = storeCode
    }
  } else {
    storeView.storeCode = config.defaultStoreCode || ''
    rootStore.state.user.current_storecode = config.defaultStoreCode || ''
  }
  if (storeViewHasChanged) {
    rootStore.state.storeView = storeView
    await I18n.getInstance().loadLanguageAsync(storeView && storeView.i18n ? storeView.i18n.defaultLocale : 'ro-RO')
  }
  if (storeViewHasChanged || Vue.prototype.$db.currentStoreCode !== storeCode) {
    if (typeof Vue.prototype.$db === 'undefined') {
      Vue.prototype.$db = {}
    }
    initializeSyncTaskStorage()
    Vue.prototype.$db.currentStoreCode = storeView.storeCode
  }
  return storeView
}

export function removeStoreCodeFromRoute (matchedRouteOrUrl: LocalizedRoute | string): LocalizedRoute | string {
  const storeCodeInRoute = storeCodeFromRoute(matchedRouteOrUrl)
  if (storeCodeInRoute !== '') {
    let urlPath = typeof matchedRouteOrUrl === 'object' ? matchedRouteOrUrl.path : matchedRouteOrUrl
    return urlPath.replace(storeCodeInRoute + '/', '')
  } else {
    return matchedRouteOrUrl
  }
}

export function localizedDispatcherRoute (routeObj: LocalizedRoute | string, storeCode: string): LocalizedRoute | string {
  const { storeCode: currentStoreCode, appendStoreCode, url: storeUrl } = currentStoreView()
  if (!storeCode || !config.storeViews[storeCode]) {
    storeCode = currentStoreCode
  }
  const appendStoreCodePrefix = storeCode && appendStoreCode

  if (typeof routeObj === 'string') {
    if (routeObj[0] !== '/') routeObj = `/${routeObj}`
    return appendStoreCodePrefix ? storeUrl ? `${storeUrl}${routeObj}` : `/${storeCode}${routeObj}` : routeObj
  }

  if (routeObj) {
    if ((routeObj as LocalizedRoute).fullPath && !(routeObj as LocalizedRoute).path) { // support both path and fullPath
      routeObj['path'] = (routeObj as LocalizedRoute).fullPath
    }

    if (routeObj.path) { // case of using dispatcher
      const routeCodePrefix = appendStoreCodePrefix ? storeUrl || `/${storeCode}` : ''
      const qrStr = queryString.stringify(routeObj.params);

      const normalizedPath = routeObj.path[0] !== '/' ? `/${routeObj.path}` : routeObj.path
      return `${routeCodePrefix}${normalizedPath}${qrStr ? `?${qrStr}` : ''}`
    }
  }

  return routeObj
}

export function localizedRoute (routeObj: LocalizedRoute | string | RouteConfig | RawLocation, storeCode?: string): any {
  if (!routeObj) {
    return routeObj
  }

  let { storeCode: currentStoreCode, url: storeUrl } = currentStoreView();

  if (!storeCode) {
    storeCode = currentStoreCode;
  }

  if ((typeof routeObj === 'object') && (routeObj as LocalizedRoute)) {
    if ((routeObj as LocalizedRoute).fullPath && !(routeObj as LocalizedRoute).path) { // support both path and fullPath
      routeObj['path'] = (routeObj as LocalizedRoute).fullPath
    }
  }

  if (storeCode && routeObj && config.defaultStoreCode !== storeCode && config.storeViews[storeCode].appendStoreCode) {
    if (typeof routeObj === 'object') {
      if (routeObj.name) {
        routeObj.name = storeCode + '-' + routeObj.name
      }

      if (routeObj.path) {
        const path = routeObj.path.startsWith('/') ? routeObj.path : `/${routeObj.path}`;
        storeUrl = (storeUrl || '').startsWith('/') ? storeUrl : `/${storeUrl}`;

        if (!path.startsWith(storeUrl) && !path.startsWith(`/${storeCode}`)) {
          routeObj.path = `/${storeCode}${path}`;
        }
      }
    } else {
      const path = routeObj.startsWith('/') ? routeObj : `/${routeObj}`;
      const hasStoreUrl = !!storeUrl;
      storeUrl = (storeUrl || '').startsWith('/') ? storeUrl : `/${storeUrl}`;

      if (
        (hasStoreUrl && !path.startsWith(storeUrl) && !path.startsWith(`/${storeCode}`)) ||
        (!hasStoreUrl && !path.startsWith(`/${storeCode}`))
      ) {
        return `/${storeCode}${path}`;
      }
    }
  }

  return routeObj
}

export function setupMultistoreRoutes (config, router: VueRouter, routes: RouteConfig[]): void {
  const allStoreRoutes = [...routes]
  if (config.storeViews.mapStoreUrlsFor.length > 0 && config.storeViews.multistore === true) {
    for (const storeCode of config.storeViews.mapStoreUrlsFor) {
      if (storeCode && (config.defaultStoreCode !== storeCode)) {
        for (const route of routes) {
          const localRoute = localizedRoute(Object.assign({}, route), storeCode)
          localRoute.meta = {
            ...(localRoute.meta || {}),
            originalPath: route.path,
          };
          allStoreRoutes.push(localRoute)
        }
      }
    }
  }
  RouterManager.getInstance().addRoutes(allStoreRoutes, router)
}
